import React, { useEffect, useState, useRef } from "react";
import { Link, graphql } from "gatsby";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import { Scrollbar, Autoplay, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/scrollbar";
import "swiper/css/navigation";
import {
  LayoutSl,
  SEO2,
  CBreadCrumb,
  AssetImage,
  LWrap2,
  RecruitNews,
  Vertical,
  CImgCard,
  WordPic,
  WordFull,
  ContainerCrumbs,
  InterviewPic,
  WordPicWords,
  CInterviewJumbotron,
  CQuoteBox,
  CInterviewMedia,
  CSectTitle,
  CSchedule,
  CStars,
  CLabelCard,
  CBtnList,
} from "../../../../components/_index";
import "../../../../assets/_sass/page/recruit/common.scss";
import { title } from "process";
import "../../../../assets/_sass/page/recruit/interview.scss";
import { useMobile } from "../../../../utils/use-series";

const SubPage = ({ data }: { data: any }) => {
  const isSp = useMobile();
  const frontmatter = data?.markdownRemark?.frontmatter;

  const verticalData = {
    title: "千田史佳",
    content: "Fumika Chida",
    cla_left: "works_big_left",
    cla_right: "works_small_right",
    exClass: "container_left_No1",
    exClassWord: "container_left_sp  no-copy",
  };

  const verticalDataTime = {
    title: " １日のスケジュール",
    content: "",
    cla_left: "works_big_left",
    cla_right: "works_small_right",
  };
  const crumbsData = [
    {
      title: "Top",
      src: "/recruit/",
    },
    {
      title: "社員インタビュー",
      src: "/recruit/interview/",
    },
    {
      title: "千田史佳",
      src: "/recruit/interview/article06/",
      exClass:"no-copy",
    },
  ];
  const interviewFullData1 = {
    title:
      "入社を決めた理由をお聞かせください",
    paragraph: [
      {
        title:
          "実家が仙台ロイヤルパークホテル（以下、当ホテル）の近くだったことから、子どもの頃から「素敵な建物だな」と憧れていました。その後、進路を考えるタイミングになってブライダル関係の専門学校へ進学し、縁あって当ホテルでアルバイトとして働くことになりました。",
      },
      {
        title:
          "実は、元々人見知りな性格で、人とお話をするのは得意ではありませんでした。ですがホテルで働くうちに、会話の楽しさや自分のサービスを通してお客さまに喜んでいただけることの素晴らしさを実感して、卒業後も「このホテルで働きたい」と強く考えるようになり、新卒の入社試験を受けました。",
      },
      {
        title:
          "一度は不採用という結果になりましたが、働きたい思いは揺らぐことなく、そのままアルバイトを続けました。そして2020年、キャリア採用という形で念願叶って正社員になることができました。",
      },
    ],
  };
  const interviewFullData2 = {
    title: "",
    paragraph: [
      {
        title:
          "当社で働くようになってから、今まで難しいと思っていたことにもチャレンジする機会が増えました。それによって、より多くのお客さまからのご要望を叶えることができるようになり、自分自身の成長がサービスの品質向上にもつながっている実感があります。",
      },
    ],
  };
  const interviewFullData3 = {
    title: "キャリアパスや、今後の目標を教えてください",
    paragraph: [
      {
        title:
          "私はまだ入社5年目ですが、これから経験を積み、スキルを磨いていくことで、将来的にはマネージャー職を目指したいと考えています。当社には女性スタッフも多く在籍しており、育児や介護などのライフステージに合わせて働き続けられる環境が整っています。例えば、時短勤務や夜勤のない部門への異動など、個々の事情に応じた柔軟な対応が可能です。このような配慮は性別を問わず、働きやすさにつながっていると感じています。",
      },
      {
        title:
          "今後は、お客さまによりご満足いただけるために提案力を磨き、新しいことにも積極的にチャレンジしていきたいです。",
      },
    ],
  };

  const interviewFullData4 = {
    title: "当社で働きたい方へ向けて、メッセージをお願いします",
    paragraph: [
      {
        title:
          "ホテルには毎日たくさんのお客さまが訪れ、それぞれに違った思いやご希望があります。そのため、一人ひとりに寄り添った接客がとても大切です。「決めつける」のではなく、まずはお話を丁寧に伺い、それを行動に反映させる姿勢を大切にしていただきたいと思います。",
      },
    ],
  };

  const picData1 = {
    img: {
      src: "/assets/images/recruit/interview/article06/interview_article06_02.png",
      alt: "",
    },
    title: "仙台ロイヤルパークホテルの魅力はどこにあると感じますか",
    paragraph: [
      {
        title:
          "当ホテルは、東北の玄関口として知られる仙台駅から最も近いリゾートホテルです。車で約30分と好アクセスで、かつ自然に囲まれた環境に立地しているため、気軽に非日常感を味わっていただけます。",
      },
      {
        title:
          "私自身、プライベートで宿泊することもありますが、滞在中は本当にゆったりとした心地で過ごせますね。内装や料理、サービスの質と、どれを取ってもレベルが高く安心感があります。",
      },
      {
        title:
          "「忙しい毎日を忘れて、ホッとリラックスできるひとときを過ごしたい」、そんなお客さまのご希望にぴったり寄り添えるホテルです。",
      },
    ],
    subcontent: "",
    // position: true,
    positionSp: true,
  };

  const picData2 = {
    img: {
      src: "/assets/images/recruit/interview/article06/interview_article06_03.png",
      alt: "",
    },
    title: "現在の業務内容を教えてください",
    paragraph: [
      {
        title:
          "婚礼部門のスタッフとして、ブライダルフェアの対応や結婚式の打合せ、プロポーズのお手伝いなどをしています。結婚式の打合せは、大体挙式の4ヵ月～4ヵ月半前から始まります。最初はおふたりの馴れ初めや挙式に対する思いなどパーソナルな面を中心に伺い、そこから具体的なプランを決めていく流れです。",
      },
      {
        title:
          "また、「もっと早くから準備したい」「細かく話したいから打合せ回数を増やしたい」とおっしゃるお客さまに対しては、ご希望に応じて柔軟に対応します。",
      },
      {
        title:
          "ホテルでプロポーズを希望されるお客さまには、おふたりにとって最高の瞬間になるよう、知恵を絞って提案させていただきます。定期的にスタッフ同士で「こんなプロポーズしたよ」と情報共有し合い、引き出しを充実させるよう工夫しています。",
      },
      {
        title:
          "勤務時間はその時々によりますが、例えば土日祝の場合、9時半に出社したらまずはメールや引き継ぎ事項のチェック、それからお客さまとの打合せやブライダルフェアの対応などが入り、19時半頃には退社となります。土日祝で長めに働いた分、平日は短いシフトになるので、プライベートの予定も立てやすいです。",
      },
    ],
    subcontent: "",
    position: true,
  };
  const picData3 = {
    img: {
      src: "/assets/images/recruit/interview/article06/interview_article06_04.png",
      alt: "",
    },
    title: "職場の雰囲気について、どう感じていますか",
    paragraph: [
      {
        title:
          "皆さんとてもあたたかく、良い意味で年齢や立場の差を感じることのないフラットな職場です。その印象は、アルバイト勤務時代からまったく変わりません。ミスがあったときも、すぐに周りのスタッフが駆けつけてフォローし合える、アットホームな関係性があります。",
      },
      {
        title:
          "また「挑戦」を大切にする風土も根づいており、新しいことに挑戦するときには「何かあってもフォローするから」と背中を押してもらえます。一から丁寧に教えてくれる仲間の存在があるからこそ、思い切って挑戦できることを実感しますね。",
      },
    ],
    subcontent: "",
    //position: true,
    positionSp: true,
  };
  const picData4 = {
    img: {
      src: "/assets/images/recruit/interview/article06/interview_article06_05.png",
      alt: "",
    },
    title: "これまでに最も達成感や充実感を得られたできごとは何ですか",
    paragraph: [
      {
        title:
          "結婚式は人生における一大イベントですから、こだわりを持って臨むお客さまばかりです。",
      },
      {
        title: (
          <>
            だからこそ挙式当日を迎え、ご新郎さまご新婦さま、またご家族の方々から感謝の言葉をいただいたときの喜びは、言葉になりません。私自身、式の様子を見るといろいろな感情が込み上げて、涙が出そうになるんです。
            <br />
            またお客さまからの難しいご要望にチャレンジし、お応えできたときには大きな達成感を得られますね。以前、照明の調整や写真の撮り方、音楽を流すタイミングなど細部に至るまでこだわっているお客さまの対応をしたことがあります。式の直前ギリギリまでつきっきりで確認作業に追われるなど、大変な面もありましたが、その分お客さまにご満足いただくことができました。
          </>
        ),
      },
    ],
    subcontent: "",
    position: true,
  };

  const picData5 = {
    img: {
      src: "/assets/images/recruit/interview/article06/interview_article06_06.png",
      alt: "",
    },
    title: "ホテルのウエディングで働く魅力は？",
    paragraph: [
      {
        title:
          "私のように、子どもの頃から憧れていたホテルで結婚式を挙げる方も多くいらっしゃいます。おふたりの思い出に寄り添ったご提案ができるのは、ホテルならではの魅力であり強みだと感じています。",
      },
      {
        title:
          "また、式を挙げたホテルがおふたりの原点となり、節目節目のタイミングでお祝いできることも大きな特徴ですね。例えば結婚式場を探している状態でブライダルフェアにお越しいただき、挙式する。ここが原点になります。その後は結婚記念日にお祝いをしたり、お子さまが生まれたらお食い初めや七五三、こうしたお祝いはすべてホテルでできます。",
      },
      {
        title:
          "式を挙げて終わりではなく、繰り返しお越しいただき思い出を積み重ねていける場所。これがホテルならではの魅力だと思いますね。",
      },
    ],
    subcontent: "",
    //position: true,
    positionSp: true,
  };

  const picData6 = {
    img: {
      src: "/assets/images/recruit/interview/article06/interview_article06_07.png",
      alt: "",
    },
    title: "",
    paragraph: [
      {
        title:
          "当社にはさまざまな部門があり、多くの人と関わる機会や新しいことにチャレンジできる環境が整っています。そのため、明確な目標がまだ定まっていない方でも、気軽に挑戦できる職場だと思います。",
      },
      {
        title:
          "「チャレンジしてみたい！」という気持ちをお持ちの方には、とても魅力的な環境だと自信を持ってお伝えします。一緒に新しい可能性を見つけていきましょう。",
      },
    ],
    subcontent: "",
    position: true,
  };

  const carouselData = [
    {
      img: {
        src: "/assets/images/recruit/interview/interview.png",
        alt: "",
      },
      title: "ザ ロイヤルパークホテル アイコニック 東京汐留 フロントチーム",
      content: (
        <>
          「人と関わる」を極めるためアニメ制作の道からホテルマンに軌道修正。
          <br />
          入社10年目を前に改めて「意思疎通や連携を大事にする会社」と実感
        </>
      ),

      end_title: "2015年 新卒入社",
      jumpUrl: "/recruit/interview/article01/",
    },
    {
      img: {
        src: "/assets/images/recruit/interview/interview2.png",
        alt: "",
      },
      title: "丸ノ内ホテル 料飲　(レストラン ポム・ダダン) ",
      content: (
        <>
          お客さまの大切な時間を共有できるのがレストラン部門の醍醐味。
          <br />
          価値ある取り組みを紹介する「真心感動ボード」も仕事への意欲に
        </>
      ),
      end_title: "2020年 新卒入社",
      jumpUrl: "/recruit/interview/article02/",
    },
    {
      img: {
        src: "/assets/images/recruit/interview/interview3.png",
        alt: "",
      },
      title: "ザ ロイヤルパーク キャンバス 大阪北浜 フロントチーム",
      content: (
        <>
          「さまざまな接客を経験したい」と、これまで経験のないフロント業務ができる当社へ。ライフスタイルホテルの最高峰を目指し、若いスタッフの意見を採り入れてイベントを実施する
        </>
      ),

      end_title: "2019年 キャリア入社",
      jumpUrl: "/recruit/interview/article03/",
    },
    {
      img: {
        src: "/assets/images/recruit/interview/interview4.png",
        alt: "",
      },
      title: "ザ ロイヤルパークホテル アイコニック 大阪御堂筋 フロントチーム",
      content: (
        <>
          学生時代に宿泊したホテルのサービスに感動し、ホテル業界で働きたいと当社へ就職。お客さまやスタッフとのコミュニケーションを高め、おもてなしの最高峰を極める
        </>
      ),

      end_title: "2020年 新卒入社",
      jumpUrl: "/recruit/interview/article04/",
    },

    {
      img: {
        src: "/assets/images/recruit/interview/interview5.png",
        alt: "",
      },
      title:
        "ロイヤルパークホテル（東京・日本橋）調理部 洋食調理二課 バンケットコールド調理",
      content: (
        <>
          作る料理に一切の妥協をしない。
          <br />
          コンクールにも挑戦し、自分の武器を増やしながら成長を重ねる毎日
        </>
      ),
      end_title: "2020年 新卒入社",
      jumpUrl: "/recruit/interview/article05/",
    },
    {
      img: {
        src: "/assets/images/recruit/interview/interview6.png",
        alt: "",
      },
      title: "仙台ロイヤルパークホテル 婚礼",
      content: (
        <>
          「不採用でもあきらめない」
          <br />
          子どもの頃の憧れを形に掴んだキャリア
        </>
      ),

      end_title: "2020年 キャリア入社",
      jumpUrl: "/recruit/interview/article06/",
    },
  ];

  return (
    <LayoutSl isKv={false}>
      <SEO2
        title={frontmatter?.title}
        description={frontmatter?.description}
        isTop={false}
      />
      <section className="l_sect04 l_sect04_sp recruit-font">
        <LWrap2>
          <div className="new_main">
            <ContainerCrumbs data={crumbsData} />
            <div className="container_main container_main_interview ">
              <Vertical data={verticalData} />
              <div className="container_right interview-container-right">
                <AssetImage
                  src="/assets/images/recruit/interview/article06/interview_article06.png"
                  alt=""
                  loading="lazy"
                  wrapClassName="article-pc"
                />

                {isSp && (
                  <AssetImage
                    src="/assets/images/recruit/interview/article06/interview_article06_media.png"
                    alt=""
                    loading="lazy"
                    wrapClassName="article-media"
                  />
                )}
                <div className="interview_title">
                  <p className="m_title_message m_title_message-media">
                    「不採用でもあきらめない」
                    <br />
                    子どもの頃の憧れを形に掴んだキャリア
                    <br />
                  </p>
                </div>
                <div className="interview_subtitle">
                  <p className="m_subtitle_message m_title_subtitle-media">
                    2020年 キャリア入社
                    <br />
                    仙台ロイヤルパークホテル 婚礼
                  </p>
                </div>
              </div>
            </div>
          </div>
        </LWrap2>
      </section>
      <section className="l_sect04 l_sect04_sp recruit-font">
        <LWrap2>
          <div className="person-article-div">
            <div className="new_main">
              <WordFull
                data={interviewFullData1}
                exClass="container_main_message_full2"
              />

              <WordPic
                data={picData1}
                exClass="container_message_picture_same container_main_message_bisect2"
                exRightClass="article06-p1-p"
              />
              <WordPic
                data={picData2}
                exRightClass="article06-p2-p"
                exClass="container_main_message_bisect2"
              />

              <WordPic
                data={picData3}
                exClass="reverse-container_main_message_bisect article06-p3-m container_main_message_bisect2"
                exRightClass="small-right-pic article06-p3-p bisect_right2 "
              />

              <WordPic
                data={picData4}
                exClass="container_message_picture_same container_main_message_bisect2"
                exRightClass="article06-p4-p"
              />

              <WordFull
                data={interviewFullData2}
                exClass="container_main_message_full2 mt--8-sp"
              />
              {/* <div className="container_main_message_full interview_article06_message container_main_message_full2">
                <div className="message_content2">
                  <p className="message_paragraph_small">
                    当社で働くようになってから、今まで難しいと思っていたことにもチャレンジする機会が増えました。それによって、より多くのお客さまからのご要望を叶えることができるようになり、自分自身の成長がサービスの品質向上にもつながっている実感があります。
                  </p>
                </div>
              </div> */}

              <WordPic
                data={picData5}
                exClass="reverse-container_main_message_bisect container_main_message_bisect2"
                exRightClass="small-right-pic bisect_right2 "
              />
              <WordFull
                data={interviewFullData3}
                exClass="container_main_message_full2"
              />
              <WordFull
                data={interviewFullData4}
                exClass="container_main_message_full2"
              />

              <WordPic
                data={picData6}
                exClass="container_message_picture_same container_main_message_bisect2"
                exRightClass="article06-p6-p"
              />
            </div>
          </div>
        </LWrap2>
      </section>
      <section className="l_sect04  u_grey recruit-font">
        <LWrap2>
          <div className="new_main u_grey">
            <div className="container_main container_main_time container_main_time-media">
              <Vertical data={verticalDataTime} />
              <div className="demo2 article01-mythBox">
                <div className="timeboxItem01">
                  <div className="mythBox">
                    <div className="timebox-left">
                      <p className="timebox-title"></p>
                      <div className="timeline-item">
                        <i className="timeline-axis bg5-01"></i>
                        <div className="timeline-content text timeline-content-media">
                          <div className="timeline-detail">
                            {" "}
                            <span className="time_span_title_left">10:00 </span>
                            <span className="time_span_title_right article-pc">
                              出勤、引継ぎ
                              <br />
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              メールチェック
                            </span>
                          </div>
                          <div className="timeline-title01 ">
                            <div className="article-timeline-media timeline-media-wd fs-14-28-sp">
                              出勤、引継ぎ、メールチェック
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="timeline-item">
                        <i className="timeline-axis bg5-02"></i>
                        <div className="timeline-content text timeline-content-media">
                          <div className="timeline-detail">
                            {" "}
                            <span className="time_span_title_left">10:30 </span>
                            <span className="time_span_title_right article-pc">
                              お打合せ①
                            </span>{" "}
                          </div>
                          <div className="timeline-title01">
                            <div className="article-timeline-media timeline-media-wd fs-14-28-sp">
                              お打合せ①
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="timeline-item">
                        <i className="timeline-axis bg5-03"></i>
                        <div className="timeline-content text timeline-content-media">
                          <div className="timeline-detail">
                            {" "}
                            <span className="time_span_title_left">13:00 </span>
                            <span className="time_span_title_right article-pc">
                              休憩
                            </span>{" "}
                          </div>
                          <div className="timeline-title01">
                            <div className="article-timeline-media timeline-media-wd fs-14-28-sp">
                              休憩
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="timeline-item">
                        <i className="timeline-axis bg5-04"></i>
                        <div className="timeline-content text timeline-content-media">
                          <div className="timeline-detail">
                            {" "}
                            <span className="time_span_title_left">14:00 </span>
                            <span className="time_span_title_right article-pc">
                              お打合せ②
                            </span>{" "}
                          </div>
                          <div className="timeline-title01">
                            <div className="article-timeline-media timeline-media-wd fs-14-28-sp">
                              お打合せ②
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="timeline-item">
                        <i className="timeline-axis bg5-05"></i>
                        <div className="timeline-content text timeline-content-media">
                          <div className="timeline-detail">
                            {" "}
                            <span className="time_span_title_left">17:00 </span>
                            <span className="time_span_title_right article-pc">
                              当日のお打合せ内容で決定した内容の手配
                              <br />
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              （引き出物やお料理、ドレスや装花等）
                              <br />
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              翌日のお打合せの準備
                            </span>
                          </div>
                          <div className="timeline-title01">
                            <div className="article-timeline-media timeline-media-wd fs-14-28-sp">
                              当日のお打合せ内容で決定した内容の手配
                              <br className="u_sp" />
                              （引き出物やお料理、ドレスや装花等）
                              <br className="u_sp" />
                              翌日のお打合せの準備
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="timeline-item">
                        <i className="timeline-axis bg5-06"></i>
                        <div className="timeline-content text timeline-content-media">
                          <div className="timeline-detail">
                            {" "}
                            <span className="time_span_title_left">18:30 </span>
                            <span className="time_span_title_right article-pc">
                              退勤
                            </span>
                          </div>
                          <div className="timeline-title01">
                            <div className="article-timeline-media timeline-media-wd fs-14-28-sp">
                              退勤
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </LWrap2>
      </section>

      <section className="l_sect04 recruit-font">
        <LWrap2>
          <div className="swiper swiper_buttons swiper_buttons_media">
            <div className="swiper-button-prev turnPrev"></div>&nbsp;
            <div className="swiper-button-next turnNext"></div>
          </div>
          <div className="new_main">
            <div className="container_main_carouse">
              {isSp ? (
                <>
                  <div className="carouse_right article-media article-swiper-media">
                    <Swiper
                      autoplay={false}
                      loop={false}
                      modules={[Scrollbar, Autoplay, Navigation]}
                      speed={1000}
                      className="c_labelCardSlider"
                      slidesPerView={1}
                      centeredSlides={false}
                      breakpoints={{
                        "768": {
                          spaceBetween: 0,
                        },
                        "769": {
                          spaceBetween: 50,
                        },
                      }}
                      spaceBetween={50}
                      pagination={{
                        clickable: true,
                      }}
                      navigation={{
                        nextEl: ".swiper-button-next",
                        prevEl: ".swiper-button-prev",
                      }}
                    >
                      {carouselData.map((item, index) => {
                        return (
                          <SwiperSlide>
                            <div className="carouse_body">
                              <Link to={item.jumpUrl}>
                                <AssetImage
                                  src={`${item.img.src}`}
                                  alt=""
                                  loading="lazy"
                                />
                                <div className="carouse_title">
                                  {item.title}
                                </div>
                                <div className="carouse_content">
                                  <p>{item.content}</p>
                                </div>
                                <div className="carouse_end">
                                  {item.end_title}
                                </div>
                              </Link>
                            </div>
                          </SwiperSlide>
                        );
                      })}
                    </Swiper>
                  </div>
                </>
              ) : (
                <>
                  <div className="carouse_right article-pc">
                    <Swiper
                      autoplay={false}
                      loop={false}
                      modules={[Scrollbar, Autoplay, Navigation]}
                      speed={1000}
                      className="c_labelCardSlider"
                      slidesPerView={3.5}
                      centeredSlides={false}
                      breakpoints={{
                        "768": {
                          spaceBetween: 0,
                        },
                        "769": {
                          spaceBetween: 50,
                        },
                      }}
                      spaceBetween={50}
                      pagination={{
                        clickable: true,
                      }}
                      navigation={{
                        nextEl: ".swiper-button-next",
                        prevEl: ".swiper-button-prev",
                      }}
                    >
                      {carouselData.map((item, index) => {
                        return (
                          <SwiperSlide>
                            <div className="carouse_body">
                              <Link to={item.jumpUrl}>
                                <AssetImage
                                  src={`${item.img.src}`}
                                  alt=""
                                  loading="lazy"
                                />
                                <div className="carouse_title">
                                  {item.title}
                                </div>
                                <div className="carouse_content">
                                  <p>{item.content}</p>
                                </div>
                                <div className="carouse_end">
                                  {item.end_title}
                                </div>
                              </Link>
                            </div>
                          </SwiperSlide>
                        );
                      })}
                    </Swiper>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="return_overview_class">
            {isSp ? (
              <>
                <Link to="/recruit/interview/" target="_blank">
                  <div className="return_overview_class_internal return-overview-media">
                    <div className="btn-list">一覧に戻る</div>
                    <div className="circle-icon">
                      <img
                        src="/assets/images/recruit/common/icon-new-right.png"
                        alt=""
                      />
                    </div>
                  </div>
                </Link>
              </>
            ) : (
              <>
                <div className="return_overview_class_internal return-overview-media hover_active">
                  一覧に戻る
                  <a href="/recruit/interview/">
                    <img
                      src="/assets/images/recruit/goto.svg"
                      className="return_overview_images"
                    />
                  </a>
                </div>
              </>
            )}
          </div>
        </LWrap2>
      </section>
    </LayoutSl>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
